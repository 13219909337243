<script>
import Vue from "vue";
import footerLayout from "../../layouts/footerLayout";
import headerLayout from "../../layouts/headerLayout";

export default {
  components: {
    headerLayout,
    footerLayout,
    // carousel,
    // VueDropdown,
  },

  data() {
    return {
      trnID: "",
    };
  },

  mounted() {
    if (this.$route.params.pageType) {
      this.pageType = this.$route.params.pageType;
    }
    this.trnID = this.$route.query.txnId.split("?");
  },
};
</script>

<template>
  <div class="container-fuild">
    <headerLayout />
    <!-- header Layout -->
    <main>
      <div class="card">
        <div class="card-body">
          <div class="inner m-2 row cardBodyPad" style="padding: 50px">
            <center>
              <div
                class="col-md-6"
                style="
                  background-color: #f37a68;
                  padding: 30px;
                  border-radius: 10px;
                  max-width: 600px;
                "
              >
                <i
                  class="fa fa-exclamation-circle fa-5x"
                  style="color: #ffff"
                  aria-hidden="true"
                ></i
                ><br /><br />
                <p
                  style="
                    text-align: center;
                    color: #ffff;
                    font-size: 20px;
                    font-family: 'Poppins';
                  "
                >
                  Your payment was not completed.<br /><br />
                  Any amount if debited from your acount, will get refunded
                  within 4-7 days.<br /><br />
                  Txn ID: {{ trnID[0] }}
                </p>
                <button
                  class="btn_1 view_details"
                  style="
                    color: #000000 !important;
                    background: #ffc60c !important;
                  "
                >
                  <router-link
                    :to="{ name: 'checkOut' }"
                    style="color: #000000 !important"
                  >
                    View Cart
                  </router-link>
                </button>
              </div>
            </center>
          </div>
        </div>
      </div>
    </main>
    <footerLayout />
    <!-- footer Layout -->
  </div>
</template>
<style scoped>
@media (max-width: 991px) {
  .cardBodyPad {
    padding: 0px !important;
  }
}
</style>